















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Task } from 'common-modules/src/store/interface/Task';
import TaskIcon from '@/components/TaskIcon.vue';
import jwlToggle from './jwlToggle.vue';
import jwlTaskMeta from './jwlTaskMeta.vue';
import LmsCourseDocument from './LmsCourseDocument.vue';
import { ExtendedRunTask } from '@/store/interface/Task';

@Component({
  components: {
    TaskIcon,
    jwlTaskMeta,
    jwlToggle,
    LmsCourseDocument,
  },
})
export default class JwlUnitTask extends Vue {
  @Prop(Object)
  jwlTask!: ExtendedRunTask | Task;

  open = false;

  toggleOpen (): void {
    this.open = !this.open;
  }

  get useDuotone (): boolean {
    return 'submitted' in this.jwlTask && this.jwlTask.submitted?.hasSubmitted;
  }

  get submitByDate (): string {
    if ('studentDeadline' in this.jwlTask && this.jwlTask.studentDeadline) {
      const date = new Date(this.jwlTask.studentDeadline);
      return date.toLocaleDateString(this.$route.params.lang, {
        timeZone: 'UTC',
      });
    }

    return '-';
  }

  get showLinkButton (): boolean {
    return 'role' in this.jwlTask
      && this.jwlTask.role === 'student'
      && !['track', 'attendance'].includes(this.jwlTask.type);
  }
}
