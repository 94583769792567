






























































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { ExtendedRunUnit, ExtendedUnit } from '@/store/interface/Unit';
import jwlUnitTask from '@/components/jwlUnitTask.vue';
import LmsCourseDocument from '@/components/LmsCourseDocument.vue';

@Component({
  components: {
    jwlUnitTask,
    LmsCourseDocument,
  },
})
export default class JwlUnit extends Vue {
  @Prop(Object)
  masterUnit!: ExtendedUnit;

  @Prop(Object)
  runUnit!: ExtendedRunUnit;

  @Prop({ type: String, default: 'wbt_preview' })
  wbtRouteName!: string;

  get unit (): ExtendedUnit | ExtendedRunUnit {
    return this.masterUnit || this.runUnit;
  }
}
