


















import Vue from 'vue';
import Component from 'vue-class-component';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    CommonIcon,
  },
})
export default class TaskIcon extends Vue {
  @Prop(String)
  taskType!: string;

  @Prop(Boolean)
  useDuotone!: boolean;

  @Prop(Boolean)
  marginRight!: boolean;

  @Prop(Boolean)
  showBackground!: boolean;

  get rootClasses (): Record<string, boolean> {
    return {
      'jwl-icon--margin-right': this.marginRight,
      'jwl-icon--show-background': this.showBackground,
    };
  }

  get taskIconName (): string {
    const { taskType } = this;
    let taskIcon;
    switch (taskType) {
      case 'submit_text':
        taskIcon = 'file-alt';
        break;
      case 'submit_work':
        taskIcon = 'arrow-to-top';
        break;
      case 'discuss':
        taskIcon = 'comments';
        break;
      case 'track':
        taskIcon = 'male';
        break;
      case 'attendance':
        taskIcon = 'handshake';
        break;
      case 'quiz':
        taskIcon = 'quiz';
        break;
      case 'survey':
        taskIcon = 'chart-bar';
        break;
      default:
        taskIcon = 'exclamation-triangle';
    }

    return taskIcon;
  }

  get taskIconStyle (): string {
    let { useDuotone } = this;

    if (['chart-bar', 'exclamation-triangle'].includes(this.taskIconName)) {
      useDuotone = false;
    }

    return useDuotone
      ? 'duotone'
      : 'regular';
  }
}
